import { useState, useEffect } from "react";
import { ref, onValue, update } from "firebase/database";
import { database } from "../utils/firebase";
import { useRecoilState } from "recoil";
import { useChipCounts } from "../state/atoms";

const Config = () => {
  const [players, setPlayers] = useState({});
  const [playerUpdates, setPlayerUpdates] = useState({});
  const [chipCounts, setChipCounts] = useRecoilState(useChipCounts);

  useEffect(() => {
    const tableRef = ref(database, "/table/players");
    const unsubscribe = onValue(tableRef, (snapshot) => {
      setPlayers(snapshot.val());
    });
    return () => unsubscribe();
  }, []);

  const handleInputChange = (id, field, value) => {
    setPlayerUpdates((prevUpdates) => ({
      ...prevUpdates,
      [id]: {
        ...prevUpdates[id],
        [field]: field === "name" ? value : parseInt(value, 10),
      },
    }));
  };

  const handlePlayerUpdate = (id) => {
    const updates = playerUpdates[id];
    if (updates) {
      const playerRef = ref(database, `/table/players/${id}`);
      update(playerRef, updates);
    }
  };

  return (

    <div className="p-4 bg-gray-900 text-white h-full overflow-y-scroll">
      <h1 className="text-3xl mb-6">Config</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      <div className="bg-gray-700 p-4 rounded shadow-md">
        <div className="mb-2">
          Enter a value to reset all players' stack and chips:
        </div>
        <div className="flex flex-col space-y-2">
          <input
            type="number"
            placeholder="Reset Stack and Chips"
            onChange={(e) => setPlayerUpdates({ resetValue: parseInt(e.target.value, 10) })}
            className="p-1 rounded bg-gray-600 text-white"
          />
          <button
            onClick={() => {
              const resetValue = playerUpdates.resetValue;
              if (resetValue !== undefined) {
                Object.keys(players).forEach((id) => {
                  const playerRef = ref(database, `/table/players/${id}`);
                  update(playerRef, { stack: resetValue, chips: resetValue });
                });
              }
            }}
            className="p-2 mt-2 bg-red-500 rounded text-white hover:bg-red-700"
          >
            Reset All Players
          </button>
        </div>
        <div className="mt-4">
          Display Chips on Livestream:
        </div>
        <div className="flex items-center space-x-2">
          <input
            type="checkbox"
            checked={chipCounts}
            onChange={() => setChipCounts(!chipCounts)}
            className="form-checkbox h-5 w-5 text-blue-600"
          />
          <div className="flex flex-row justify-between w-full"> 
          <span className="text-white">Show Chip Counts</span>
          <span className="text-white">{chipCounts ? "ON" : "OFF"}</span>
          </div>
        </div>
      </div>

      {Object.entries(players).map(([id, player]) => (
          <div key={id} className="bg-gray-700 p-4 rounded shadow-md">
            <div className="mb-2">
              <span className="block">Name: {player.name}</span>
              <span className="block">Seat: {id}</span>
              <span className="block">Stack: {player.stack}</span>
              <span className="block">Chips: {player.chips}</span>
            </div>
            <div className="flex flex-col space-y-2">
              <input
                type="text"
                placeholder="New Name"
                onChange={(e) => handleInputChange(id, "name", e.target.value)}
                className="p-1 rounded bg-gray-600 text-white"
              />
              <input
                type="number"
                placeholder="New Stack"
                onChange={(e) => handleInputChange(id, "stack", e.target.value)}
                className="p-1 rounded bg-gray-600 text-white"
              />
              <input
                type="number"
                placeholder="New Chips"
                onChange={(e) => handleInputChange(id, "chips", e.target.value)}
                className="p-1 rounded bg-gray-600 text-white"
              />
              <button
                onClick={() => handlePlayerUpdate(id)}
                className="p-2 mt-2 bg-blue-500 rounded text-white hover:bg-blue-700"
              >
                Submit
              </button>
            </div>
          </div>
        ))}
        </div>
      </div>
  );
};

export default Config;
