import React from "react";
import { ReactComponent as VillainIcon } from "../assets/villain.svg";
import { ReactComponent as Cards } from "../assets/hidden_cards.svg";
import deck, { CardName } from "../Deck";
import { PlayerProp } from "@/utils/types/PlayerProp";
import styles from "../Board.module.scss";

interface VillainProps extends PlayerProp {
  onHandOver: () => void; // Add this new prop
}

const Villain: React.FC<VillainProps> = ({
  playerName,
  position,
  stackSize,
  cards,
  fold,
  allin,
  action,
  playerTurn,
  player,
  handOver,
  onHandOver,
}) => {
  return (
    <div className={player}>
      {/* Show active villain's cards after hand over */}
      {!fold &&
        (handOver && cards ? (
          <div className={styles.cardsIcon}>
            <div className={styles.villainCardPair}>
              {cards.map((card: CardName, index: number) => {
                const CardComponent = deck[card];
                if (!CardComponent) return null;
                return <CardComponent key={index} className={styles.card} />;
              })}
            </div>
          </div>
        ) : (
          <div className={styles.cardsIcon}>
            {cards.length == 2 && <Cards />}
          </div>
        ))}

      <VillainIcon
        className={`${styles.villainIcon} ${
          !handOver && !fold && !allin && playerTurn ? styles.glow : ""
        }`}
        onClick={onHandOver} // Attach the click handler here
      />
      <div className={styles.stackSize}>
        {fold ? (
          "Folded"
        ) : (
          <>
            {playerName} <br /> {`$${stackSize}`}
          </>
        )}
      </div>
    </div>
  );
};

export default Villain;
