import { useState, useEffect } from "react";
import { Routes, Route, Link, Switch } from "react-router-dom";
import Table from "./Table";
import Tracker from "./Tracker";
import Livestream from "./Livestream";
import Home from "./Home";
import Config from "./Config";
import PrivateRoute from "../components/privateRoute";
import Navbar from "../components/navbar";
import Login from "./Login";
import { AuthProvider } from "../contexts/authContext";
const App = () => {

  return (
    <div className="flex flex-col h-full w-full bg-transparent text-white overflow-hidden">
      <Navbar />
      <AuthProvider>
        <Routes>
          {/* <Route path="/" element={<Home playerCards={playerCards} communityCards={communityCards} />} /> */}
          <Route
            path="/"
            element={
              <PrivateRoute >
                <Table />
              </PrivateRoute>
            }
          />
          <Route path="/action" element={
            <PrivateRoute>
              <Tracker />
            </PrivateRoute>
          } />
          <Route path="/livestream"
            element={
              <PrivateRoute>
                <Livestream />
              </PrivateRoute>
            }
          />
          <Route path="/config"
            element={
              <PrivateRoute>
                <Config />
              </PrivateRoute>
            }
          />
          <Route path="/login" element={<Login />} />
        </Routes>
      </AuthProvider>
    </div>
  );
};

export default App;
