import { Link } from "react-router-dom";

const Navbar = () => {
  return (
      <nav className="p-4 bg-gray-800">
        {/* <Link to="/" className="mr-4">
          Home
        </Link> */}
        <Link to="/" className="mr-4">
          Table
        </Link>
        <Link to="/action" className="mr-4">
          Action Tracker
        </Link>
        <Link to="/livestream" className="mr-4">
          Livestream
        </Link>
        <Link to="/config" className="mr-4">
          Config
        </Link>
    </nav>
  );
};

export default Navbar;
