import styles from './Board.module.scss';

export function generateSeats(playerCards: any, players: any) {
  // Define seat positions as P1 to P10
  const positions = Array.from({ length: 10 }, (_, i) => `P${i + 1}`);

  // Filter out empty seats from playerCards and get the actual seats occupied
  const occupiedSeats = Object.keys(playerCards).filter(seat => playerCards[seat] && playerCards[seat].length > 0);

  // Map each occupied seat to a seat object using the players' data
  const newSeats = occupiedSeats.map((seat, index) => {
    const playerData = players[seat];

    return {
      playerName: playerData.name || `Player ${seat}`, // Use the name from Firebase or a default
      position: seat, // Use the seat key (e.g., P1, P2)
      stackSize: playerData.chips || 0, // Use the stack from Firebase
      cards: playerData.cards,
      fold: playerData.folded || false,
      allin: playerData.allin || false,
      checked: playerData.checked || false,
      currentBet: playerData.current_bet || 0,
      // action: getPlayerAction(seat, players), // Function to determine the player's current action
      player: styles[`villain${index + 1}`] || styles.defaultPlayer, // Use dynamic styling or a default
    };
  });

  return newSeats;
}

// // Helper function to determine the player's current action based on Firebase actions
// const getPlayerAction = (playerId: any, players: any) => {
//   // Assuming you have access to the actions data
//   // You might need to pass `actions` to `generateSeats` as well
//   // Here's a basic example assuming actions are accessible globally or via closure

//   // Replace this with actual access to actions
//   // For example, you could pass `data.actions` to `generateSeats`
//   const latestAction = Object.values(actions)
//     .filter((action: any) => action.playerId === playerId)
//     .sort((a: any, b: any) => b.timestamp - a.timestamp)[0];

//   return latestAction ? latestAction.action : "";
// };