import React, { useState, useEffect } from "react";
import {
  ref,
  onValue,
  push,
  runTransaction,
  update,
  set,
} from "firebase/database";
import {database} from "../utils/firebase";

const Tracker = () => {
  const [players, setPlayers] = useState({});
  const [actions, setActions] = useState([]);
  const [currentStreet, setCurrentStreet] = useState("preflop");
  const [tableData, setTableData] = useState({});

  useEffect(() => {
    const tableRef = ref(database, "/table");

    const unsubscribe = onValue(tableRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setPlayers(data.players || {});

        // Convert actions object to an array and include player names
        const actionsArray = data.actions
          ? Object.keys(data.actions)
              .map((key) => ({
                id: key,
                ...data.actions[key],
                playerName:
                  data.players[data.actions[key].playerId]?.name ||
                  data.actions[key].playerId,
              }))
              .sort((a, b) => a.timestamp - b.timestamp) // Sort actions by timestamp
          : [];
        setActions(actionsArray);

        // Update currentStreet from Firebase if needed
        if (data.street) {
          setCurrentStreet(data.street);
        }
        setTableData(data);
      }
    });

    return () => unsubscribe();
  }, []);

  // Function to handle new actions
  const handleAction = async (playerId, actionType, amount = 0) => {
    try {
      const player = players[playerId];
      if (!player) {
        alert("Player does not exist.");
        return;
      }

      // Prepare the action object
      const action = {
        playerId,
        playerName: player.name,
        action: actionType,
        amount,
        timestamp: Date.now(),
      };

      // Add the new action to the actions object with player's name
      const actionsRef = ref(database, "/table/actions");
      const newActionRef = push(actionsRef);
      await set(newActionRef, action);

      // Initialize update object for multi-path updates
      const updates = {};

      // Update player's chips based on action type
      if (actionType === "call" || actionType === "bet") {
        const playerChipsRef = `/table/players/${playerId}/chips`;
        await runTransaction(ref(database, playerChipsRef), (currentChips) => {
          if (currentChips === null) return; // Player doesn't exist
          let newChips = currentChips;
          switch (actionType) {
            case "call":
            case "bet":
              newChips -= amount;
              break;
            default:
              break;
          }
          return newChips;
        });
      }

      // Update player's state based on action type
      if (actionType === "fold") {
        updates[`/table/players/${playerId}/folded`] = true;
      }
      if (actionType === "allin") {
        updates[`/table/players/${playerId}/allin`] = true;
      }
      if (actionType === "check") {
        updates[`/table/players/${playerId}/checked`] = true;
      }

      // Update the player object if needed
      if (Object.keys(updates).length > 0) {
        await update(ref(database), updates);
      }

      // Update table object: position, pot, currentBet
      const tableUpdate = {};

      // Update position to the name of the player who acted
      tableUpdate["/table/position"] = player.name;

      // Update pot and currentBet based on action type
      if (
        actionType === "call" ||
        actionType === "bet" ||
        actionType === "allin"
      ) {
        // Increment pot by the amount
        tableUpdate["/table/pot"] = (tableData.pot || 0) + amount;

        // Update currentBet if action is "bet" or "allin"
        if (actionType === "bet") {
          tableUpdate["/table/currentBet"] = amount;
        } else if (actionType === "allin") {
          tableUpdate["/table/currentBet"] = player.chips; // Assuming all-in amount is the remaining chips
        }
      } else if (actionType === "win") {
        // Award the pot to the player
        tableUpdate[`/table/players/${playerId}/chips`] =
          (tableData.players[playerId]?.chips || 0) + tableData.pot;
        tableUpdate["/table/pot"] = 0;
      }

      // Additionally, update player's currentBet
      if (actionType === "bet" || actionType === "allin") {
        tableUpdate[`/table/players/${playerId}/currentBet`] = amount;
      } else if (actionType === "call") {
        tableUpdate[`/table/players/${playerId}/currentBet`] =
          currentStreet === "preflop" ? 0 : amount;
      }

      // Apply table updates
      if (Object.keys(tableUpdate).length > 0) {
        await update(ref(database), tableUpdate);
      }

      // If action is "win", update player's chips
      if (actionType === "win") {
        const potAmount = player.currentBet || 0;
        await runTransaction(
          ref(database, `/table/players/${playerId}/chips`),
          (currentChips) => {
            if (currentChips === null) return; // Player doesn't exist
            return currentChips + potAmount;
          }
        );
      }

      // Reset checked fields after action is processed
      if (actionType === "check") {
        // Optionally, reset player's checked field in the next move or when moving to next street
        // Here, we reset immediately for simplicity
        updates[`/table/players/${playerId}/checked`] = false;
        await update(ref(database), updates);
      }
    } catch (error) {
      console.error("Error handling action:", error);
      alert("There was an error processing your action. Please try again.");
    }
  };

  // Function to handle street change
  const handleStreetChange = async (street) => {
    try {
      // Update the street in the table object
      await update(ref(database, "/table"), {
        street,
        currentBet: 0, // Reset currentBet for the new street
      });

      // Reset all players' currentBet and checked fields
      const resetPlayersUpdates = {};
      Object.keys(players).forEach((playerId) => {
        resetPlayersUpdates[`/table/players/${playerId}/currentBet`] = 0;
        resetPlayersUpdates[`/table/players/${playerId}/checked`] = false;
      });
      await update(ref(database), resetPlayersUpdates);

      setCurrentStreet(street);
    } catch (error) {
      console.error("Error updating street:", error);
      alert("There was an error updating the street. Please try again.");
    }
  };
  // Function to handle hand reset
  const handleHandReset = async () => {
    try {
      const resetPlayersCards = {};
      Object.keys(players).forEach((playerId) => {
        resetPlayersCards[`/table/players/${playerId}/cards`] = [];
        resetPlayersCards[`/table/players/${playerId}/folded`] = false;
      });
      // Reset community cards
      const resetCommunityCards = {
        "/table/community_cards": [],
      };

      // Combine player cards reset and community cards reset
      const updates = {
        ...resetPlayersCards,
        ...resetCommunityCards,
      };

      await update(ref(database), updates);
    } catch (error) {
      console.error("Error resetting hand:", error);
      alert("There was an error resetting the hand. Please try again.");
    }
  };

  // Get the latest action for each player
  const latestActions = {};
  actions.forEach((action) => {
    if (
      !latestActions[action.playerId] ||
      action.timestamp > latestActions[action.playerId].timestamp
    ) {
      latestActions[action.playerId] = action;
    }
  });

  return (
    <div className="p-4 bg-gray-900 text-white h-full overflow-y-scroll">
      <h1 className="text-3xl mb-6">Action Tracker</h1>
      {/* <h3 className="text-2xl mb-6">Pot: ${tableData.pot}</h3> */}

      {/* Street Buttons */}
      {/* <div className="mb-6">
        <h2 className="text-2xl mb-2">Current Street: {currentStreet}</h2>
        <div className="flex space-x-4">
          <button
            onClick={() => handleStreetChange("preflop")}
            className={`px-4 py-2 rounded ${
              currentStreet === "preflop"
                ? "bg-blue-700"
                : "bg-blue-500 hover:bg-blue-700"
            }`}
          >
            Preflop
          </button>
          <button
            onClick={() => handleStreetChange("flop")}
            className={`px-4 py-2 rounded ${
              currentStreet === "flop"
                ? "bg-blue-700"
                : "bg-blue-500 hover:bg-blue-700"
            }`}
          >
            Flop
          </button>
          <button
            onClick={() => handleStreetChange("turn")}
            className={`px-4 py-2 rounded ${
              currentStreet === "turn"
                ? "bg-blue-700"
                : "bg-blue-500 hover:bg-blue-700"
            }`}
          >
            Turn
          </button>
          <button
            onClick={() => handleStreetChange("river")}
            className={`px-4 py-2 rounded ${
              currentStreet === "river"
                ? "bg-blue-700"
                : "bg-blue-500 hover:bg-blue-700"
            }`}
          >
            River
          </button>
        </div>
      </div> */}

      {/* Hand Reset Button */}
      <div className="mb-6">
        <button
          onClick={handleHandReset}
          className="px-4 py-2 bg-red-500 hover:bg-red-700 text-white font-bold rounded"
        >
          Reset Hand
        </button>
      </div>
      <div className="mb-6">
        <label className="block text-white text-sm font-bold mb-2" htmlFor="totalPot">
          Total Pot:
        </label>
        <input
          type="number"
          id="totalPot"
          value={tableData.pot || 0}
          onChange={(e) => {
            const newPot = parseInt(e.target.value, 10);
            if (!isNaN(newPot)) {
              setTableData((prevData) => ({
                ...prevData,
                pot: newPot,
              }));
            }
          }}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
        <button
          onClick={() => {
            update(ref(database, "/table"), { pot: tableData.pot });
          }}
          className="mt-2 px-4 py-2 bg-blue-500 hover:bg-blue-700 text-white font-bold rounded"
        >
          Update Pot
        </button>
      </div>

      {/* Players Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {Object.entries(players).map(([playerId, player]) => (
          <PlayerTracker
            key={playerId}
            playerId={playerId}
            name={player.name}
            chips={player.chips}
            latestAction={latestActions[playerId]}
            onAction={handleAction}
            isFolded={player.folded}
            isAllin={player.allin}
            tableData={tableData} // Pass tableData as a prop
          />
        ))}
      </div>

      {/* Display Action History */}
      {/* <div className="mt-8">
        <h2 className="text-2xl mb-4">Action History</h2>
        <ul className=" bg-gray-800 p-4 rounded">
          {actions
            .slice()
            .sort((a, b) => a.timestamp - b.timestamp)
            .map((action) => (
              <li key={action.id} className="mb-2">
                <strong>{action.playerName}</strong>:{" "}
                {action.action.charAt(0).toUpperCase() + action.action.slice(1)}{" "}
                {action.amount > 0 ? `$${action.amount}` : ""}
              </li>
            ))}
        </ul>
      </div> */}
    </div>
  );
};

const PlayerTracker = ({
  playerId,
  name,
  chips,
  latestAction,
  onAction,
  isFolded,
  isAllin,
  tableData, // Add tableData as a prop
}) => {
  const [actionInput, setActionInput] = useState("");
  const [amount, setAmount] = useState("");
  const [chipCount, setChipCount] = useState(chips);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!actionInput) return;

    const parsedAmount = parseInt(amount, 10) || 0;

    // Validate amount for actions that require it
    if (
      (actionInput === "call" || actionInput === "bet") &&
      parsedAmount <= 0
    ) {
      alert("Please enter a valid amount.");
      return;
    }

    // Prevent actions if player is folded or all-in
    if (isFolded) {
      alert("This player has folded and cannot take actions.");
      return;
    }
    if (isAllin) {
      alert("This player is all-in and cannot take further actions.");
      return;
    }

    onAction(playerId, actionInput, parsedAmount);
    setActionInput("");
    setAmount("");
  };

  const handleFold = () => {
    if (isFolded) {
      alert("This player has already folded.");
      return;
    }
    onAction(playerId, "fold", 0);
  };

  const handleBet = () => {
    const parsedAmount = parseInt(amount, 10);
    if (isNaN(parsedAmount) || parsedAmount <= 0) {
      alert("Please enter a valid bet amount.");
      return;
    }
    if (parsedAmount > chipCount) {
      alert("Insufficient chips for this bet.");
      return;
    }
    setChipCount(chipCount - parsedAmount);
    onAction(playerId, "bet", parsedAmount);
    setAmount("0");
  };

  const handleWin = () => {
    const newChipCount = chipCount + (tableData.pot || 0);
    setChipCount(newChipCount);
    onAction(playerId, "win", tableData.pot || 0);
  };

  return (
    <div className="p-4 bg-gray-800 rounded shadow">
      <h2 className="text-2xl mb-2">{name}</h2>
      <p className="mb-1">Chips: ${chipCount}</p>
      <form onSubmit={handleSubmit} className="flex flex-col">
        <button
          type="button"
          onClick={handleFold}
          className="mt-2 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
          disabled={isFolded || isAllin}
        >
          Fold
        </button>
      </form>
      <div className="mt-2 flex flex-col">
        <label className="mt-4 mb-1">Bet Amount:</label>
        <input
          type="number"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          className="mb-2 p-2 bg-gray-700 text-white rounded"
          min="0"
        />
        <button
          type="button"
          onClick={handleBet}
          className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
        >
          Bet
        </button>
        <button
          type="button"
          onClick={handleWin}
          className="mt-2 bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded"
        >
          Win
        </button>
      </div>
    </div>
  );
};

export default Tracker;
