import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDSHHtJ4qPx30yGKuXLwEckLFXehLejdPk",
  authDomain: "stream-my-poker-table.firebaseapp.com",
  databaseURL: "https://stream-my-poker-table-default-rtdb.firebaseio.com",
  projectId: "stream-my-poker-table",
  storageBucket: "stream-my-poker-table.firebasestorage.app",
  messagingSenderId: "476399577542",
  appId: "1:476399577542:web:c351aba22003aa8cfd6ac7",
  measurementId: "G-EBB3BWKK67"
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const auth = getAuth(app);

export { database, auth, signInWithEmailAndPassword, createUserWithEmailAndPassword };
