import React, { useEffect, useState, Suspense } from "react";
import { ReactComponent as TableSVG } from "./assets/table.svg";
import Villain from "./game/Villain";
import deck, { CardName } from "./Deck";
import { ReactComponent as Chip } from "./assets/chip.svg";
import { ReactComponent as Dealer } from "./assets/dealer.svg";
import { generateSeats } from "./generateSeats";
import styles from "./Board.module.scss";
import { Settings } from "@/utils/types/BoardProp";
import { ReactComponent as BlankCard } from "./assets/blank_card.svg";
interface BoardProps extends Settings {
  communityCards: string[];
  bettingRound: any;
  currentTurn: string;
  returnObj: any;
  seats: any[];
  onSeatClick: (seat: string) => void;
}

const Board: React.FC<BoardProps> = ({
  hero,
  numPlayers,
  stackSize,
  communityCards,
  bettingRound,
  currentTurn,
  returnObj,
  seats,
}) => {
  const [playerCount, setPlayerCount] = useState(0);
  const [chipStack, setChipStack] = useState(100);
  const [action, setAction] = useState("");
  const [dealerPosition, setDealerPosition] = useState("");
  const [handOver, setHandOver] = useState(false);

  const handleHandOver = () => {
    setHandOver(prevState => !prevState); // Toggle between true and false
  };

  console.log("seats", seats);
  useEffect(() => {
    // setStartPosition(hero);
    setPlayerCount(numPlayers);
    setChipStack(stackSize);
    setDealerPosition(
      numPlayers === 2
        ? "SB"
        : ["SB", "BB", "UTG", "HJ", "CO", "BTN"][numPlayers - 1] || "SB" // Default to "SB" if undefined
    );
  }, [hero, numPlayers, stackSize]); // new game

//   const heroPlayer = bettingRound?.players?.[startPosition];
  return (
    <div className={styles.board}>
      <div className={styles.boardArea}>
        <TableSVG className={styles.table} />

        <div className={styles.pot}>Pot: {bettingRound?.pot}</div>

        {/* Ensuring that bettingRound is non-null, meaning we are past the first game iteration
        {handOver && bettingRound && (
          <div className={styles.winner}>
            {returnObj.winner.join(", ")} wins with {returnObj.winning_hand}!
          </div>
        )} */}

        {/* Render community cards based on the game state */}
        <div className={styles.communityCards}>
          <Suspense fallback={<div>Loading cards...</div>}>
            {/* Map over all community cards and render each one or a blank card if they're not out yet */}
            {Array.from({ length: 5 }).map((_, index) => {
              const cardClassNames = [
                styles.flop1,
                styles.flop2,
                styles.flop3,
                styles.turn,
                styles.river,
              ];
              const CardComponent = deck[communityCards[index] as CardName]; // Cast to CardName
              return CardComponent ? (
                <CardComponent key={index} className={cardClassNames[index]} />
              ) : (
                <BlankCard key={index} className={cardClassNames[index]} />
              );
            })}
          </Suspense>
        </div>

        <div className={styles.action}>{action}</div>

        {/* Hero-related UI features
        Hero bet
        {(heroPlayer?.currentBet > 0 || heroPlayer?.checked) && (
          <div className={styles.betHero}>
            {heroPlayer.currentBet > 0 ? (
              <>
                <Chip />
                <span>{heroPlayer.currentBet}</span>
              </>
            ) : (
              <>
                <Chip />
                <span>check</span>
              </>
            )}
          </div>
        )}

        Hero dealer
        {startPosition === dealerPosition && (
          <div className={styles.dealerHero}>
            <Dealer />
          </div>
        )}

        Hero object itself
        <Hero
          playerName="Hero"
          position={startPosition}
          stackSize={heroPlayer?.stack}
          cards={[]}
          fold={heroPlayer?.folded}
          allin={heroPlayer?.allin}
          action=""
          playerTurn={currentTurn === startPosition}
          player={styles.hero}
          handOver={handOver}
        /> */}

        {/* Villain-related UI features */}
        {seats.map((villain, i) => {
          const villainNumber = villain.playerName.match(/\d+/)?.[0];
          const player = bettingRound?.players?.[villain.position];
          return (
            <React.Fragment key={i}>
              {/* Villain bet */}
              {(player?.currentBet > 0 || player?.checked) && (
                <div className={styles[`betVillain${villainNumber}`]}>
                  {player.currentBet > 0 ? (
                    <>
                      <Chip />
                      <span>{player.currentBet}</span>
                    </>
                  ) : (
                    <>
                      <Chip />
                      <span>check</span>
                    </>
                  )}
                </div>
              )}

              {/* Villain dealer */}
              {/* {villain.position === dealerPosition && (
                <div className={styles[`dealerVillain${villainNumber}`]}>
                  <Dealer />
                </div>
              )} */}

              {/* Villain object itself */}
              <Villain
                key={i}
                playerName={villain.playerName}
                position={villain.position}
                stackSize={player?.stack}
                cards={returnObj?.[villain.position]?.hand}
                fold={player?.folded}
                allin={player?.allin}
                action={villain.action}
                playerTurn={currentTurn === villain.position}
                player={villain.player}
                handOver={handOver}
                // onHandOver={handleHandOver} // toggle to show cards
                onHandOver={()=> {}}
              />
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default Board;
