import { useState, useEffect } from "react";
import CardImage from "../components/cardImage";
import { database } from "../utils/firebase";
import { ref, onValue } from "firebase/database";
const Home = () => {
  const [playerCards, setPlayerCards] = useState({});
  const [communityCards, setCommunityCards] = useState([]);

  useEffect(() => {
    const tableRef = ref(database, "/table");
    const unsubscribe = onValue(tableRef, (snapshot) => {
      const data = snapshot.val();
      updateTableData(data);
    });
    return () => unsubscribe();
  }, []);

  const updateTableData = (data) => {
    const newPlayerCards = {};
    for (let i = 1; i <= 10; i++) {
      const key = `P${i}`;
      if (data.players && data.players[key] && data.players[key].cards) {
        // Format cards to have the first letter capitalized
        newPlayerCards[key] = data.players[key].cards.map((card) => {
          if (card) {
            return card.charAt(0).toUpperCase() + card.slice(1);
          }
          return card; // Return empty string if card is empty
        });
      }
    }
    // console.log("newPlayerCards", newPlayerCards);
    setPlayerCards(newPlayerCards);
    setCommunityCards(data.community_cards && Array.isArray(data.community_cards) ? data.community_cards : []);
  };

  return (

    <div className="flex h-full w-full flex-row overflow-y-scroll bg-gray-800">
      <div className="flex flex-col w-1/2">
        <h1 className="text-4xl">Player Cards</h1>
        {Object.entries(playerCards).map(([key, value]) => (
          <div className="flex flex-row" key={key}>
            <div>{key}</div>
            {value &&
              value.length > 0 &&
              value.some((card) => card !== "") ? (
              value.map((card, index) =>
                card ? <CardImage key={index} card={card} /> : null // Only render CardImage if card is not empty
              )
            ) : (
              <div className="my-6">No cards scanned</div>
            )}
          </div>
        ))}
      </div>
      <div className="flex flex-col w-1/2 justify-end items-end">
        <h1 className="text-4xl">Community Cards</h1>
        <div className="flex flex-row">
          {communityCards.map((card, index) => (
            <CardImage key={index} card={card} />
          ))}
        </div>
        </div>
      </div>
  );
};

export default Home;
