import { useState, useEffect } from "react";
import CardImage from "../components/cardImage";
import {database} from "../utils/firebase";
import { ref, onValue } from "firebase/database";
import { useRecoilValue } from "recoil";
import { useChipCounts as useChipCountsAtom } from "../state/atoms";

const Livestream = () => {
  const [playerCards, setPlayerCards] = useState({});
  const [communityCards, setCommunityCards] = useState([]);
  const [playerMapping, setPlayerMapping] = useState({});
  const [tableData, setTableData] = useState({});
  const [playerOdds, setPlayerOdds] = useState({});
  const useChipCounts = useRecoilValue(useChipCountsAtom);

  async function evaluator(evaluation, data) {
    try 
    {
    console.log("data evaluator", data, evaluation);
      const response = await fetch(
        "https://onova-cors-proxy.vercel.app/poker-evaluator.vercel.app/poker-evaluator",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            evaluation,
            data,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const result = await response.json();
      return result.message;
    } catch (error) {
      console.error(`Error calling the ${evaluation} endpoint:`, error);
    }
  }
  // // useeffect to get playernames from the database
  // useEffect(() => {
  //   const playerNamesRef = ref(database, "/player_names");
  //   const unsubscribe = onValue(playerNamesRef, (snapshot) => {
  //     const data = snapshot.val();
  //     console.log("data", data);
  //     setPlayerMapping(data);
  //   });
  //   return () => unsubscribe();
  // }, []);

  useEffect(() => {
    const tableRef = ref(database, "/table");
    const unsubscribe = onValue(tableRef, (snapshot) => {
      const data = snapshot.val();
      console.log("data", data);
      updateTableData(data);
    });
    return () => unsubscribe();
  }, []);

  const updateTableData = (data) => {
    const newPlayerCards = {};
    for (let i = 1; i <= 10; i++) {
      const key = `P${i}`;
      if (data.players && data.players[key] && data.players[key].cards) {
        // Format cards to have the first letter capitalized
        newPlayerCards[key] = data.players[key].cards.map((card) => {
          if (card) {
            return card.charAt(0).toUpperCase() + card.slice(1);
          }
          return card; // Return empty string if card is empty
        });
      }
    }
    console.log("newPlayerCards", newPlayerCards);
    setPlayerCards(newPlayerCards);
    console.log("data.community_cards", data.community_cards);
    setCommunityCards(
       data.community_cards && Array.isArray(data.community_cards)
         ? data.community_cards
         : []
    );
    setPlayerMapping(data.players || {});
    setTableData(data);
  };

  console.log("communityCards", communityCards);
  useEffect(() => {
    const determineStage = () => {
      const len = communityCards.length;
      if (len === 0) return "preflop";
      if (len === 3) return "flop";
      if (len === 4) return "turn";
      if (len === 5) return "river";
      return null;
    };

    const extractPlayerHands = () => {
      return Object.values(playerMapping)
        .filter(player => !player.folded && player.cards) // Check for non-folded players with cards
        .map(player => player.cards);
    };

    const fetchPlayerOdds = async () => {
      const stage = determineStage();
      if (!stage) {
        console.log("Stage not applicable for evaluator.");
        return;
      }

      const playerHands = extractPlayerHands();
      const numPlayers = playerHands.length;

      if (numPlayers === 0) {
        console.log("No player hands available for evaluation.");
        return;
      }

      // Define cycles based on stage if needed; using 1000 for all stages as per example
      const cycles = 1000;
      // Prepare data payload for the evaluator
      const evaluatorData = {
        knownPartialHands: playerHands,
        community: communityCards,
        playerCount: numPlayers,
        cycles: cycles,
      };

      // Call the evaluator
      const evaluatorResponse = await evaluator("winningOddsForTable", evaluatorData);

      if (evaluatorResponse && evaluatorResponse.players) {
        // Map the odds to the respective player positions
        // Assuming playerMapping has keys like P1, P2, etc., corresponding to playerHands order
        const updatedPlayerOdds = {};
        const notFoldedPlayerMapping =  Object.values(playerMapping)
            .filter(player => !player.folded && player.cards) ;
        Object.entries(notFoldedPlayerMapping).forEach(([playerId, player], index) => {
          if (evaluatorResponse.players[index]) {
            updatedPlayerOdds[player.name] = {
              winRate: evaluatorResponse.players[index].winRate,
              splitRates: evaluatorResponse.players[index].splitRates,
            };
          }
        });

        console.log("Updated Player Odds:", updatedPlayerOdds, determineStage());
        setPlayerOdds(updatedPlayerOdds);
      } else {
        console.error("Invalid response from evaluator:", evaluatorResponse);
      }
    };

    // Call the fetchPlayerOdds function
    fetchPlayerOdds();
  }, [communityCards, playerCards, playerMapping]);

  return (
    <div className="flex h-full w-full flex-row overflow-y-scroll text-black">
      <div className="flex flex-col w-1/2 justify-end ">
        <br />
        {Object.entries(playerCards).map(([key, value]) => (
          !playerMapping[key].folded && (
            <div className="flex flex-row">
              <div className="flex flex-col w-22 mb-2" key={value}>
                <div className="flex flex-row justify-between">
                  {value &&
                  value.length > 0 &&
                  value.some((card) => card !== "") ? (
                    value.map(
                      (card, index) =>
                        card ? <CardImage card={card} /> : null // Only render CardImage if card is not empty
                    )
                  ) : (
                    <div className="my-6">No cards scanned</div>
                  )}
                </div>
                <div className="font-bold text-sm text-white bg-gradient-to-b from-zinc-700 to-zinc-800 border-2 border-zinc-800 truncate w-[5.5rem] h-full items-center flex">
                  {playerMapping[key].name}
                </div>
              </div>
              {useChipCounts && <div className="flex flex-col w-32 mb-2 justify-between" key={key}>
                <div className="font-bold text-xs leading-7 text-white bg-gradient-to-b from-red-700 to-red-800 border-2 border-red-800 truncate pl-1">
                  {/* ${tableData.currentBet} to call TODO: Add this back when we fully implement actions */} 
                  {/* Bet: ${playerMapping[key].currentBet} */}
                  { playerOdds[playerMapping[key].name] ? `${(playerOdds[playerMapping[key].name].winRate * 100).toFixed(0)}%` : "-%"}
                </div>
                <div className="font-bold text-xs leading-7 text-white bg-gradient-to-b from-zinc-700 to-zinc-800 border-2 border-zinc-800 border-l-0 pl-1 truncate">
                  Chips: ${playerMapping[key].chips}
                </div>
              </div>}
            </div>
          )
        ))}
      </div>
      <div className="flex flex-col w-1/2 justify-end items-end">
        <div className="flex flex-row w-[14rem] justify-between">
          {communityCards.map((card, index) => (
            <CardImage key={index} card={card} />
          ))}
          {Array.from({ length: 5 - communityCards.length }).map((_, index) => (
            <div key={index} className="w-[44px] border-zinc-800"></div>
          ))}
        </div>
        <div className="flex flex-row w-[14rem]">
          <div className="font-bold w-full text-lg leading-7 text-white bg-gradient-to-b from-zinc-700 to-zinc-800 border-2 border-zinc-800 truncate flex flex-row">
            <div className="w-1/2 uppercase">Pot</div>
            <div className="w-1/2 text-right">${tableData.pot}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Livestream;
