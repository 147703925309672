import React, { useState, useEffect } from "react";
import { ref, onValue, update } from "firebase/database";
import {database} from "../utils/firebase";
import Board from "../components/Board";
import styles from "../components/Board.module.scss";
import { suits, ranks } from "../components/Deck";
import { generateSeats } from "../components/generateSeats";

const Table = () => {
  const [playerCards, setPlayerCards] = useState({});
  const [communityCards, setCommunityCards] = useState([]);
  const [seats, setSeats] = useState([]);
  const [deck, setDeck] = useState([]);
  const [tableData, setTableData] = useState({});

  //generate deck of cards
  function generateDeck() {
    const deck = [];
    for (const suit of suits) {
      for (const rank of ranks) {
        deck.push(convertCardFormatBack(`${suit}_${rank}`));
      }
    }
    return deck;
  }

  // Shuffle the deck using the Fisher-Yates algorithm
  function shuffleDeck(deck) {
    for (let i = deck.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [deck[i], deck[j]] = [deck[j], deck[i]];
    }
    return deck;
  }

  useEffect(() => {
    const tableRef = ref(database, "/table");
    const unsubscribe = onValue(tableRef, (snapshot) => {
      const data = snapshot.val();
      // console.log("data", data);
      updateTableData(data);
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const newDeck = shuffleDeck(generateDeck());
    setDeck(newDeck);
  }, []);

  // Update the player cards and community cards based on the data from Firebase
  const updateTableData = (data) => {
    const newPlayerCards = {};
    const occupiedSeats = [];

    // Collect player cards and count occupied seats
    for (let i = 1; i <= 10; i++) {
      const key = `P${i}`;
      if (data.players && data.players[key] && data.players[key].cards) {
        newPlayerCards[key] = data.players[key].cards;
        occupiedSeats.push(key);
      }
    }

    setPlayerCards(newPlayerCards);
    setCommunityCards(Array.isArray(data.community_cards) ? data.community_cards : []);

    const generatedSeats = generateSeats(newPlayerCards, data.players);
    setSeats(generatedSeats);

    setTableData(data);
  };

  // Add a new player
  const addPlayer = () => {
    // console.log("addPlayer");
    // Find the first available seat
    for (let i = 1; i <= 10; i++) {
      const seatKey = `P${i}`;
      if (!playerCards[seatKey]) {
        const newPlayer = {
          // TEMP CHANGE BACK (FOR TESTING)
          cards: [deck.pop(), deck.pop()], // Default to empty cards
          stack: 100,
          chips: 100,
          folded: false,
          allin: false,
          checked: false,
          currentBet: 0,
          name: `P${i}`,
        };
        const tableRef = ref(database, `/table/players/${seatKey}`);
        update(tableRef, newPlayer);
        break;
      }
    }
  };

  // Convert cards from "Qh" format to "Hearts_Q" format
  function convertCardFormat(card) {
    if (card === "") {
      return "";
    }
    const rankChar = card[0].toUpperCase();
    const suitChar = card[1].toLowerCase();
    const rankIndex = rankChar === "T" ? 8 : ranks.indexOf(rankChar);
    const suitIndex = ["c", "d", "h", "s"].indexOf(suitChar);
    const rank = rankChar === "T" ? "10" : ranks[rankIndex];
    const suit = suits[suitIndex];
    return `${suit}_${rank}`;
  }

  // convert card from "Hearts_Q" format to "Qh" format
  function convertCardFormatBack(card) {
    const suitsMap = {
      Clubs: "c",
      Diamonds: "d",
      Hearts: "h",
      Spades: "s",
    };
    const [suit, rank] = card.split("_");
    const suitChar = suitsMap[suit];
    const rankChar = rank.replace("10", "T");
    return `${rankChar}${suitChar}`;
  }

  // Set up the settings for the board
  const settings = {
    hero: "P1",
    numPlayers: seats.length,
    stackSize: 100,
  };

  console.log("tableData", tableData);
  // Initialize bettingRound based on generated seats
  const bettingRound = {
    numPlayers: seats.length,
    smallBlind: tableData.small_blind,
    bigBlind: tableData.big_blind,
    seatingOrder: seats.map((seat) => seat.position),
    players: seats.reduce((acc, seat) => {
      acc[seat.position] = {
        name: seat.playerName,
        stack: seat.stackSize,
        currentBet: seat.currentBet,
        folded: seat.fold,
        allin: seat.allin,
        checked: seat.checked,
      };
      return acc;
    }, {}),
    pot: tableData.pot,
    currentBet: tableData.currentBet,
    roundInProgress: tableData.street,
  };

  // Initialize returnObj with hands for each player
  const returnObj = seats.reduce((acc, seat) => {
    acc[seat.position] = {
      hand: seat.cards.map(convertCardFormat),
      preflop_odds: {},
    };
    return acc;
  }, {});

  const currentTurn = "river";

  return (
    <div className="flex flex-col h-full w-full bg-gray-800 text-white">

    <div className={styles.playContainer}>
      {/* <div className={styles.controls}>
        <button onClick={addPlayer}>+</button>
      </div> */}

      <Board
        {...settings}
        communityCards={communityCards.map(convertCardFormat)}
        bettingRound={bettingRound}
        currentTurn={currentTurn}
        returnObj={returnObj}
        seats={seats}
      />
    </div>
    </div>
  );
};

export default Table;
