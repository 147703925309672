import React, { useState } from "react"
import { useAuth } from "../contexts/authContext"
import { useNavigate } from "react-router-dom"

export default function Login() {

  const { signInWithEmail, signUpWithEmail, currentUser, logout } = useAuth()
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();

  async function handleEmailSignIn(e) {
    e.preventDefault()
    try {
      setError("")
      setLoading(true)
      await signInWithEmail(email, password)
      console.log("Logged in with Email")
      navigate("/")
    } catch (error) {
      console.log("Error:", error)
      setError("Failed to log in with Email")
    }
    setLoading(false)
  }

  async function handleEmailSignUp(e) {
    e.preventDefault()
    try {
      setError("")
      setLoading(true)
      await signUpWithEmail(email, password)
      console.log("Signed up and logged in with Email")
      navigate("/")
    } catch (signUpError) {
      console.log("Error:", signUpError)
      setError("Failed to sign up with Email")
    }
    setLoading(false)
  }

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-800">
      <div className="bg-white p-6 rounded shadow-md w-full max-w-md text-black">
        <h2 className="text-center text-2xl font-bold mb-4 text-gray-800">Log In</h2>
        {error && <div className="bg-red-100 text-red-700 p-2 rounded mb-4">{error}</div>}
        {!currentUser ? (
          <form className="space-y-4">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              className="w-full px-3 py-2 border rounded-md"
              required
            />
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              className="w-full px-3 py-2 border rounded-md"
              required
            />
            <button
              onClick={handleEmailSignIn}
              disabled={loading}
              className="w-full flex justify-center py-2 px-4 mt-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Sign in with Email
            </button>
            <button
              onClick={handleEmailSignUp}
              disabled={loading}
              className="w-full flex justify-center py-2 px-4 mt-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
            >
              Sign up with Email
            </button>
          </form>
        ) : (
          <div className="text-center mt-3 text-gray-500">
            {currentUser ? currentUser.email : "No user logged in"}
            <button onClick={logout} className="text-indigo-600 hover:text-indigo-500">Sign out</button>
          </div>
        )}
      </div>
    </div>
  )
}
