import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../contexts/authContext";

const PrivateRoute = ({ children }) => {
  const { currentUser } = useAuth();

  // current user email must exist in a whitelist of emails
  const whitelist = ["hpunjani15@gmail.com", "lors.kushtov@gmail.com", "victor@onova.io", "zaki@onova.io"]

  return currentUser && whitelist.includes(currentUser.email) ? children : <Navigate to="/login" />;
  // return children;
}

export default PrivateRoute;
