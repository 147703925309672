import React from "react";

// Import each card SVG individually
import { ReactComponent as Clubs2 } from "./assets/cards/Clubs_2.svg";
import { ReactComponent as Clubs3 } from "./assets/cards/Clubs_3.svg";
import { ReactComponent as Clubs4 } from "./assets/cards/Clubs_4.svg";
import { ReactComponent as Clubs5 } from "./assets/cards/Clubs_5.svg";
import { ReactComponent as Clubs6 } from "./assets/cards/Clubs_6.svg";
import { ReactComponent as Clubs7 } from "./assets/cards/Clubs_7.svg";
import { ReactComponent as Clubs8 } from "./assets/cards/Clubs_8.svg";
import { ReactComponent as Clubs9 } from "./assets/cards/Clubs_9.svg";
import { ReactComponent as Clubs10 } from "./assets/cards/Clubs_10.svg";
import { ReactComponent as ClubsJ } from "./assets/cards/Clubs_J.svg";
import { ReactComponent as ClubsQ } from "./assets/cards/Clubs_Q.svg";
import { ReactComponent as ClubsK } from "./assets/cards/Clubs_K.svg";
import { ReactComponent as ClubsA } from "./assets/cards/Clubs_A.svg";

import { ReactComponent as Diamonds2 } from "./assets/cards/Diamonds_2.svg";
import { ReactComponent as Diamonds3 } from "./assets/cards/Diamonds_3.svg";
import { ReactComponent as Diamonds4 } from "./assets/cards/Diamonds_4.svg";
import { ReactComponent as Diamonds5 } from "./assets/cards/Diamonds_5.svg";
import { ReactComponent as Diamonds6 } from "./assets/cards/Diamonds_6.svg";
import { ReactComponent as Diamonds7 } from "./assets/cards/Diamonds_7.svg";
import { ReactComponent as Diamonds8 } from "./assets/cards/Diamonds_8.svg";
import { ReactComponent as Diamonds9 } from "./assets/cards/Diamonds_9.svg";
import { ReactComponent as Diamonds10 } from "./assets/cards/Diamonds_10.svg";
import { ReactComponent as DiamondsJ } from "./assets/cards/Diamonds_J.svg";
import { ReactComponent as DiamondsQ } from "./assets/cards/Diamonds_Q.svg";
import { ReactComponent as DiamondsK } from "./assets/cards/Diamonds_K.svg";
import { ReactComponent as DiamondsA } from "./assets/cards/Diamonds_A.svg";

import { ReactComponent as Hearts2 } from "./assets/cards/Hearts_2.svg";
import { ReactComponent as Hearts3 } from "./assets/cards/Hearts_3.svg";
import { ReactComponent as Hearts4 } from "./assets/cards/Hearts_4.svg";
import { ReactComponent as Hearts5 } from "./assets/cards/Hearts_5.svg";
import { ReactComponent as Hearts6 } from "./assets/cards/Hearts_6.svg";
import { ReactComponent as Hearts7 } from "./assets/cards/Hearts_7.svg";
import { ReactComponent as Hearts8 } from "./assets/cards/Hearts_8.svg";
import { ReactComponent as Hearts9 } from "./assets/cards/Hearts_9.svg";
import { ReactComponent as Hearts10 } from "./assets/cards/Hearts_10.svg";
import { ReactComponent as HeartsJ } from "./assets/cards/Hearts_J.svg";
import { ReactComponent as HeartsQ } from "./assets/cards/Hearts_Q.svg";
import { ReactComponent as HeartsK } from "./assets/cards/Hearts_K.svg";
import { ReactComponent as HeartsA } from "./assets/cards/Hearts_A.svg";

import { ReactComponent as Spades2 } from "./assets/cards/Spades_2.svg";
import { ReactComponent as Spades3 } from "./assets/cards/Spades_3.svg";
import { ReactComponent as Spades4 } from "./assets/cards/Spades_4.svg";
import { ReactComponent as Spades5 } from "./assets/cards/Spades_5.svg";
import { ReactComponent as Spades6 } from "./assets/cards/Spades_6.svg";
import { ReactComponent as Spades7 } from "./assets/cards/Spades_7.svg";
import { ReactComponent as Spades8 } from "./assets/cards/Spades_8.svg";
import { ReactComponent as Spades9 } from "./assets/cards/Spades_9.svg";
import { ReactComponent as Spades10 } from "./assets/cards/Spades_10.svg";
import { ReactComponent as SpadesJ } from "./assets/cards/Spades_J.svg";
import { ReactComponent as SpadesQ } from "./assets/cards/Spades_Q.svg";
import { ReactComponent as SpadesK } from "./assets/cards/Spades_K.svg";
import { ReactComponent as SpadesA } from "./assets/cards/Spades_A.svg";

// Define suits and ranks as constant tuples
export const suits = ["Clubs", "Diamonds", "Hearts", "Spades"] as const;
export const ranks = ["2", "3", "4", "5", "6", "7", "8", "9", "10", "J", "Q", "K", "A"] as const;

// Create types based on suits and ranks
type Suit = (typeof suits)[number];
type Rank = (typeof ranks)[number];
export type CardName = `${Suit}_${Rank}`; // Export CardName type

// Define the deck type to map each CardName to a React component
type Deck = {
  [key in CardName]: React.ComponentType<any>;
};

// Define the deck object with each card component mapped to a CardName
const deck: Deck = {
  Clubs_2: Clubs2,
  Clubs_3: Clubs3,
  Clubs_4: Clubs4,
  Clubs_5: Clubs5,
  Clubs_6: Clubs6,
  Clubs_7: Clubs7,
  Clubs_8: Clubs8,
  Clubs_9: Clubs9,
  Clubs_10: Clubs10,
  Clubs_J: ClubsJ,
  Clubs_Q: ClubsQ,
  Clubs_K: ClubsK,
  Clubs_A: ClubsA,

  Diamonds_2: Diamonds2,
  Diamonds_3: Diamonds3,
  Diamonds_4: Diamonds4,
  Diamonds_5: Diamonds5,
  Diamonds_6: Diamonds6,
  Diamonds_7: Diamonds7,
  Diamonds_8: Diamonds8,
  Diamonds_9: Diamonds9,
  Diamonds_10: Diamonds10,
  Diamonds_J: DiamondsJ,
  Diamonds_Q: DiamondsQ,
  Diamonds_K: DiamondsK,
  Diamonds_A: DiamondsA,

  Hearts_2: Hearts2,
  Hearts_3: Hearts3,
  Hearts_4: Hearts4,
  Hearts_5: Hearts5,
  Hearts_6: Hearts6,
  Hearts_7: Hearts7,
  Hearts_8: Hearts8,
  Hearts_9: Hearts9,
  Hearts_10: Hearts10,
  Hearts_J: HeartsJ,
  Hearts_Q: HeartsQ,
  Hearts_K: HeartsK,
  Hearts_A: HeartsA,

  Spades_2: Spades2,
  Spades_3: Spades3,
  Spades_4: Spades4,
  Spades_5: Spades5,
  Spades_6: Spades6,
  Spades_7: Spades7,
  Spades_8: Spades8,
  Spades_9: Spades9,
  Spades_10: Spades10,
  Spades_J: SpadesJ,
  Spades_Q: SpadesQ,
  Spades_K: SpadesK,
  Spades_A: SpadesA,
};

export default deck;
