import React from 'react';

const CardImage = ({ card }) => {
  const convertCardName = (card) => {
    const updated_card = card.toLowerCase();
    const value = updated_card[0];
    const suit = updated_card[1];
    const allSuits = {
      's': 'Spades',
      'h': 'Hearts',
      'd': 'Diamonds',
      'c': 'Clubs'
    }

    return `${value.toUpperCase()}-${allSuits[suit]}`;
  }
  return <img className='-mb-7 border-2 border-zinc-800' src={`./images/cards/${convertCardName(card)}.png`} alt={card} width={44} height={60}/>;
};

export default CardImage;
